const payments = [
  {
    text: "M-Pesa",
    enable: true,
    type: "MPESA_KENYA",
    merchantId: 9,
    img: "../assets/images/home/m_pasa.png",
    checkPrecedence: (phone) => {
      return true;
    },
  },
  {
    text: "M-Pesa",
    enable: true,
    type: "MPESA_KENYA",
    merchantId: 16,
    img: "../assets/images/home/mpasa.png",
    checkPrecedence: (phone) => {
      return true;
    },
  },
  ,
  {
    text: "M-Pesa",
    enable: true,
    type: "MPESA_KENYA",
    merchantId: 35,
    img: "",
    checkPrecedence: (phone) => {
      return true;
    },
  },
];
export default {
  payments: payments.filter((payment) => payment.enable),
};
